import React, { createContext, useState } from 'react';
import './scss/styles.scss';
import LoaderComponent from './components/loader/LoaderComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RouteConfig from './RouteConfig';

export const LoaderContext = createContext();
function App() {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
        <LoaderComponent />
        <ToastContainer />
        <RouteConfig/>
      </LoaderContext.Provider>
    </>
  );
}
export default App;
