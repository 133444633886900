import React from "react";
import { Route, Routes } from 'react-router-dom';


const Layout = React.lazy(() => import('./layouts/Layout'));
const ElectricSupplyCharge = React.lazy(() => import('./pages/electricSupplyCharge/ElectricSupplyCharge'));
const HourlySupplyCharge = React.lazy(() => import('./pages/hourlySupplyCharge/HourlySupplyCharge'));
const ValueStackEnergy = React.lazy(() => import('./pages/valueStackEnergy/ValueStackEnergy'));
const PageNotFound = React.lazy(() => import('./pages/page404/PageNotFound'));
const PageInternalServer = React.lazy(() => import('./pages/page500/PageInternalServer'));
const BadRequest = React.lazy(() => import('./pages/page400/PageBadRequest'));

const RouteConfig = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Layout component={<ElectricSupplyCharge />} />} />
                <Route path="/electricsupplycharge" element={<Layout component={<ElectricSupplyCharge />} />} />
                <Route path="/hourlysupplycharge" element={<Layout component={<HourlySupplyCharge />} />} />
                <Route path="/valuestackenergy" element={<Layout component={<ValueStackEnergy />} />} />
                <Route path="/pagenotfound" element={<PageNotFound />} />
                <Route path="/pageinternalserver" element={<PageInternalServer />} />
                <Route path="/pagebadrequest" element={<BadRequest />} />
            </Routes>
        </div>
    );
};

export default RouteConfig;