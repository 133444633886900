import React, { useContext } from 'react';
import '../../scss/styles.scss';
import { LoaderContext } from '../../App';
import { RotatingLines } from 'react-loader-spinner';

function LoaderComponent() {
  const { isLoading } = useContext(LoaderContext);
  const displayLoader = isLoading ? "block" : "none";
  return (
    <div data-testid="loader-wrapper" className="loader-wrapper" style={{ display: displayLoader }}>
      <div className="loading-spinner">
        <RotatingLines
          strokeColor="#001b86"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    </div>
  );
}

export default LoaderComponent;
